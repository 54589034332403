import BusyBody from "../services/busy_body";

window.addEventListener('DOMContentLoaded', () =>
  new BusyBody({
    selector: 'iframe',
    added: (iframe) => {
      iframe.addEventListener('load', function (event) {
        if (event.target?.dataset) {
          event.target.dataset.loaded = true;
        }
      });
    }
  })
)
